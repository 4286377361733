.app-container {
    display: flex;
    /* Full height of the viewport */
  }
  
  .sidebar {
    flex: 0 0 auto; /* Adjust the width as needed */
    background-color: #f1f1f1; /* Set a background color for the sidebar */
  }
  
  .main-content {
    flex-grow: 1; /* Allow the main content to grow and fill the remaining space */
    background-color: #ffffff;
  }
  
  .prefill{
    background-color: rgb(234, 255, 248);
  }

  .success{
    background-color: #96dddc !important;
    color: #ffffff;
  }
  .secondary{
    background-color: #53958f !important;
    color: #ffffff;
  }

  .result_checker_panel{
    width: 400px;
    height: 400px;
  }

  .logo{
    width: 100px;
    height: 100px;
  }
  .footer{
    height: 240px;
    background-color: #008020;
    margin-top: 10px;
  }
  
  /* Add any other styling as needed */
  